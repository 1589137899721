import { Injectable } from '@angular/core'
import { map, Observable, of } from 'rxjs'
import { ApiHelper } from './api.helper'
import {
  IAffiliateCreateDTO,
  ISearchResponseDTO,
  ISessionCreateDTO
} from '../../common/models/domain/dto'
import {
  EAccountRoleType,
  IAccount,
  IAccountModel,
  IAffiliate, IAffiliateFraudStateChange,
  IAffiliateModel,
  IBoxAffiliate,
  IBoxBrand,
  IBoxModel,
  IBrand, IBrandIntegration,
  IBrandIntegrationModel,
  IBrandModel, IBrokerIntegrationRequestHistory,
  ICountry, IIntegrationTemplateConfig, ILanguage,
  ILead, ILeadChangesHistory, ILeadCommentAttachment, ILeadCommentModel, ILeadInject,
  ILeadModel, ILeadRandomData,
  ISessionModel, ITimezone,
} from 'src/app/common/models/domain/models'
import { ICompany, ICompanyModel, ICompanySettings } from '../../common/models/domain/models/company.model'
import {
  IVerticalTemplate,
  IVerticalTemplateModel,
  IVerticalTemplateSettings
} from '../../common/models/domain/models/verticalTemplate.model'
import { ILeadCreateDTO, ILeadFraudCheck } from '../../common/models/domain/dto/lead.dto'
import { IBrokerCreateDTO, IBrokerIntegrationCreateDTO, IBoxCreateDTO } from '../../common/models/domain/dto'
import { IVerticalTemplateCreateDTO } from '../../common/models/domain/dto/verticalTemplate.model'
import { IIntegrationTemplatePresetModel } from '../../common/models/domain/models/integration-template-preset.model'
import { ApiGroup, InjectorApiRoute, TrackerApiRoute } from './enums/api-endpoints.enum'
import { CursorSearchRequestParams, OffsetSearchRequestParams } from '../../common/models/search-request-params.model'
import { ApiEndpointParams } from './enums/api-endpoint-params.enum'
import {
  EPostbackType,
  IAffiliatePostback,
  IAffiliatePostbackModel
} from '../../common/models/domain/models/affiliatePostback.model'
import { ISendingScenario, ISendingScenarioModel } from '../../common/models/domain/models/injector/scenario.model'
import { IConversionHold, IConversionHoldModel } from '../../common/models/domain/models/conversionHold.model'
import { ISubscriptionModel } from '../../common/models/domain/models/subscription.model'
import { IBotSubscriber } from '../../common/models/domain/models/botSubscriber.model'
import { IStatusGroup } from '../../modules/dashboard/views/pages/status-groups/models/status-group.model'
import { ICountryGroup } from '../../modules/dashboard/views/pages/country-groups/models/country-groups.model'
import { ABaseApiService } from '../../common/abstracts/a-base-api.service'
import { LeadsUploadParamsModel, LeadsUploadResponse } from '../../common/models/domain/models/leadsUploadParamsModel'
import { PortalSettingsModel } from '../../common/models/domain/models/portalSettings.model'
import { TableKey } from '../../modules/dashboard/shared/components/modals/column-settings/table-columns.service'
import { TableColumnsModel } from '../../common/models/ui/table.model'
import { StatusGroupsModel } from '../../modules/dashboard/views/pages/metrics/models/status-groups.model'

@Injectable()
export class ApiService extends ABaseApiService {

  public searchCompanies = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<ICompany>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/tracker/company`, ...params })
  }

  public searchAffiliates = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IAffiliate>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }`, ...params })
  }

  public searchProxies = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<any>> => {
    return this.getOffsetSearchRequest({
      url: `${ this.api }/injector/proxyService`, ...params, sort: {
        active: 'createdAt',
        direction: 'asc'
      }
    })
  }

  public getStatusGroups = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IStatusGroup>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/tracker/statusGroup/group`, ...params })
  }

  public searchAffiliatePostbacks(affiliateId: string, params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IAffiliatePostback>> {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ TrackerApiRoute.POSTBACK }/${ affiliateId }`, ...params })
  }

  public searchLeads = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<ILead>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }`, ...params })
  }

  public searchLeadRegistrations = (params: Partial<OffsetSearchRequestParams>, leadId: string): Observable<ISearchResponseDTO<any>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REGISTRATION }/${ leadId }`, ...params })
  }

  public searchLeadHistory = (leadId: string, params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<ILeadChangesHistory>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.HISTORY }/${ leadId }`, ...params })
  }

  public searchLeadComments = (leadId: string, params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<ILeadCommentModel>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/comment/${ leadId }`, ...params })
  }

  public downLoadLeads(params: Partial<OffsetSearchRequestParams>): Observable<any> {
    return this.http.get<ISearchResponseDTO<any>>(ApiHelper.getOffsetSearchUrl(
      { url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REPORT }/${ TrackerApiRoute.DOWNLOAD }`, ...params }
    ), { responseType: 'blob' as any })
  }

  public searchAccounts = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IAccountModel>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }`, ...params })
  }

  public searchBrands = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IBrand>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }`, ...params })
  }

  public searchBrandsIntegrations = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IBrandIntegration>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.INTEGRATION }/crm`, ...params })
  }

  public searchBrandIntegrationHistory = (brokerId: string, integrationId: string, params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IBrokerIntegrationRequestHistory>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ brokerId }/${ TrackerApiRoute.INTEGRATION }/${ integrationId }/${ TrackerApiRoute.REQUEST_HISTORY }`, ...params })
  }

  public searchIntegrationTemplates = (params: Partial<CursorSearchRequestParams>): Observable<ISearchResponseDTO<IIntegrationTemplateConfig>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.INTEGRATOR }`, ...params })
  }

  public searchHubs = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IBoxModel>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }`, ...params })
  }

  public searchVerticals = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IVerticalTemplate>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.VERTICAL_TEMPLATE }`, ...params })
  }

  public searchScenarios = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<ISendingScenario>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.INJECTOR }/${ InjectorApiRoute.SCENARIO }`, ...params })
  }

  public searchConversions = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IConversionHold>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.CONVERSION }/${ TrackerApiRoute.HOLD }`, ...params })
  }

  public searchBotSubscriber = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IBotSubscriber>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.RESOURCES }/${ TrackerApiRoute.BOT_SUBSCRIBER }`, ...params })
  }

  public searchCountries = (): Observable<ISearchResponseDTO<ICountry>> => {
    return this.http.get<ISearchResponseDTO<ICountry>>(`${ this.api }/${ TrackerApiRoute.GEO }/${ TrackerApiRoute.COUNTRIES }`)
  }

  public searchCountryGroups = (): Observable<ISearchResponseDTO<ICountryGroup>> => {
    return this.http.get<ISearchResponseDTO<ICountryGroup>>(`${ this.api }/tracker/countryGroup/group`)
  }

  public searchLanguages = (): Observable<ISearchResponseDTO<ILanguage>> => {
    return this.http.get<ISearchResponseDTO<ILanguage>>(`${ this.api }/${ TrackerApiRoute.GEO }/${ TrackerApiRoute.LANGUAGES }`)
  }

  public searchTimezones = (): Observable<ISearchResponseDTO<ITimezone>> => {
    return this.http.get<ISearchResponseDTO<ITimezone>>(`${ this.api }/${ TrackerApiRoute.GEO }/${ TrackerApiRoute.TIMEZONES }`)
  }

  public searchStatusGroups = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<IStatusGroup>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/tracker/statusGroup/group`, ...params })
  }

  public signIn({ email, password }: ISessionCreateDTO): Observable<ISessionModel> {
    return this.http.post<ISessionModel>(`${ this.api }/${ TrackerApiRoute.SESSION }`, { email, password })
  }

  public getLeadById(id: string): Observable<ILeadModel> {
    return this.http.get<ILeadModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ id }`)
  }

  public getLeadRegistrationById(leadId: string, registrationId: string): Observable<any> {
    return this.http.get<any>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REGISTRATION }/${ leadId }/${ registrationId }`)
  }

  public createLead(body: ILeadCreateDTO): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }`, body)
  }

  public randomData(verticalTemplateId: string, countryCode: string): Observable<ILeadRandomData> {
    return this.http.get<ILeadRandomData>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/generate/random-data?verticalTemplateId=${ verticalTemplateId }&countryCode=${ countryCode }`)
  }

  public deleteLead(id: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ id }`)
  }

  public deleteLeadBulk(ids: string[]): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/bulk`, { body: { ids } })
  }

  public fraudCheck(body: ILeadFraudCheck): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/checkFraud`, body)
  }

  public markLeadAsTest(id: string, body: Partial<ILeadModel>): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ id }/${ TrackerApiRoute.TEST }`, body)
  }

  public markLeadAsTestBulk(ids: string[], body: Partial<ILeadModel>): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/bulk/${ TrackerApiRoute.TEST }`, {
      ...body,
      ids
    })
  }

  public unholdLeadConversion(id: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ id }/unholdConversion`, { isConversionHeld: false })
  }

  public patchLeadDepositStatus(leadId: string, body: Partial<ILeadModel>): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.STATUS }/${ TrackerApiRoute.DEPOSIT }/${ leadId }`, body)
  }

  public patchLead(id: string, body: Partial<ILeadModel>): Observable<ILeadModel> {
    return this.http.patch<ILead>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ id }`, body)
  }

  public executePostback(id: string, postbackType: EPostbackType): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ id }/${ TrackerApiRoute.POSTBACK }/execute`, { postbackType })
  }

  public registrationRawStatus(leadId: string, registrationId: string, rawStatus: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REGISTRATION }/${ leadId }/${ registrationId }/raw`, { rawStatus })
  }

  public registrationCrmId(leadId: string, registrationId: string, crmId: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REGISTRATION }/${ leadId }/${ registrationId }/crmId`, { crmId })
  }

  public registrationFrozenBrandStatus(leadId: string, registrationId: string, frozenBrandStatus: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REGISTRATION }/${ leadId }/${ registrationId }/frozenBrandStatus`, { frozenBrandStatus })
  }

  public markRegistrationAsDeposited(leadId: string, registrationId: string, isDeposited: boolean): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REGISTRATION }/${ leadId }/${ registrationId }/deposit`, { isDeposited })
  }

  public separateLeadRegistration(leadId: string, registrationId: string): Observable<ILead> {
    return this.http.patch<ILead>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REGISTRATION }/${ leadId }/${ registrationId }/separate`, {})
  }

  public activateRegistration(leadId: string, registrationId: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.REGISTRATION }/${ leadId }/${ registrationId }/activate`, {})
  }

  public injectLeads(body: ILeadInject): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.INJECT }`, body)
  }

  public getAccount(): Observable<IAccountModel> {
    return this.http.get<IAccountModel>(`${ this.api }/${ TrackerApiRoute.AUTH }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.MY }/${ TrackerApiRoute.PROFILE }`)
  }

  public changePassword(body: { oldPassword: string, newPassword: string }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ TrackerApiRoute.AUTH }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.MY }/${ TrackerApiRoute.PROFILE }/${ TrackerApiRoute.CHANGE_PASS }`, body)
  }

  public updateMyAccount(body: Partial<IAccountModel>): Observable<IAccountModel> {
    return this.http.patch<IAccountModel>(`${ this.api }/${ TrackerApiRoute.AUTH }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.MY }/${ TrackerApiRoute.PROFILE }`, body)
  }

  public getAccountById(id: string): Observable<IAccountModel> {
    return this.http.get<IAccountModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ id }`)
  }

  public createAccount(account: {
    firstName: string,
    lastName: string,
    password?: string,
    type: EAccountRoleType
  }): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }`, account)
  }

  public createCompanyAccount(account: {
    accountId: string,
    companyId: string,
    type: EAccountRoleType
  }): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.ROLE }/${ TrackerApiRoute.COMPANY }`, account)
  }

  public accountLookup(email: string): Observable<ISearchResponseDTO<Partial<IAccount>>> {
    return this.http.get<ISearchResponseDTO<Partial<IAccount>>>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.LOOKUP }/${ TrackerApiRoute.LIST }?${ ApiEndpointParams.EMAIL }=${ email }`)
  }

  public deleteAccount(id: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ id }`)
  }

  public patchTrackerAccount(id: string, body: Partial<IAccountModel>): Observable<IAccountModel> {
    return this.http.patch<IAccountModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ id }`, body)
  }

  public patchAccountRole(id: string, body: Partial<IAccountModel>): Observable<IAccountModel> {
    return this.http.patch<IAccountModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.ROLE }/${ TrackerApiRoute.COMPANY }/${ id }`, body)
  }

  public getAffiliateById(id: string): Observable<IAffiliateModel> {
    return this.http.get<IAffiliateModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ id }`)
  }

  public createAffiliate(affiliate: IAffiliateCreateDTO): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }`, affiliate)
  }

  public updateAffiliate(id: string, affiliate: Partial<IAffiliate>): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ id }`, affiliate)
  }

  public updateAffiliateAutologinDeviceCheck(id: string, isAutologinDeviceCheckON: boolean): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ id }/settings/autologinDeviceCheck`, { isAutologinDeviceCheckON })
  }

  public affiliateFraudCheck(id: string, body: IAffiliateFraudStateChange): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ id }/settings/fraudChecker`, body)
  }

  public deleteAffiliate(id: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ id }`)
  }

  public revokeAffiliateApiKey(id: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ id }/settings/revokeApiKey`, {})
  }

  public createAffiliatePostback(id: string, affiliate: IAffiliateCreateDTO): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ TrackerApiRoute.POSTBACK }/${ id }`, affiliate)
  }

  public updateAffiliatePostback(affiliateId: string, postbackId: string, affiliate: IAffiliateCreateDTO): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ TrackerApiRoute.POSTBACK }/${ affiliateId }/${ postbackId }`, affiliate)
  }

  public getAffiliatePostback(affiliateId: string, postbackId: string): Observable<IAffiliatePostbackModel> {
    return this.http.get<IAffiliatePostbackModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ TrackerApiRoute.POSTBACK }/${ affiliateId }/${ postbackId }`)
  }

  public assignAccountToAffiliate(affiliateId: string, accountId: string): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ TrackerApiRoute.ASSIGN }/${ affiliateId }/${ accountId }`, {})
  }

  public assignAccountToBrand(brandId: string, accountId: string): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.ASSIGN }/${ brandId }/${ accountId }`, {})
  }

  public deleteAssignmentAccountToAffiliate(affiliateId: string, accountId: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.AFFILIATE }/${ TrackerApiRoute.ASSIGN }/${ affiliateId }/${ accountId }`, {})
  }

  public deleteAssignmentAccountToBrand(brandId: string, accountId: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.ASSIGN }/${ brandId }/${ accountId }`, {})
  }

  public getBrandById(id: string): Observable<IBrandModel> {
    return this.http.get<IBrandModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ id }`)
  }

  public createBrand(brand: IBrokerCreateDTO): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }`, brand)
  }

  public updateBrand(id: string, brand: Partial<IBrandModel>): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ id }`, brand)
  }

  public changeBrandFraudBlockState(id: string, body: {
    isFraudBlockON: boolean,
    maxAcceptableFraudScore: number
  }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ id }/settings/fraudBlock`, body)
  }

  public deleteBrand(id: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ id }`)
  }

  public getBrandIntegrationById(integrationId: string): Observable<IBrandIntegrationModel> {
    return this.http.get<IBrandIntegrationModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.INTEGRATION }/crm/${ integrationId }`)
  }

  public deleteBrandIntegration(integrationId: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.INTEGRATION }/crm/${ integrationId }`)
  }

  public brandIntegrationSyncUpdates(integrationId: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.INTEGRATION }/crm/${ integrationId }/sync-updates`, {})
  }

  public brandIntegrationSyncConversions(integrationId: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.INTEGRATION }/crm/${ integrationId }/sync-conversions`, {})
  }

  public updateBrandIntegration(brandId: string, integrationId: string, body: Partial<IBrandIntegrationModel>): Observable<IBrandIntegrationModel> {
    return this.http.patch<IBrandIntegrationModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.INTEGRATION }/crm/${ integrationId }`, {
      ...body,
      brokerId: brandId
    })
  }

  public createBrandIntegration(body: IBrokerIntegrationCreateDTO): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ TrackerApiRoute.INTEGRATION }/crm`, body)
  }

  public changeBrandIntegration(integrationId: string, brandId: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/broker/integration/crm/${ integrationId }/changeBrand`, { id: brandId })
  }

  public changeLeadAffiliate(leadId: string, affiliateId: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/${ leadId }/changeAffiliate`, { affiliateId })
  }

  public changeLeadBrand(leadId: string, body: any): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/${ leadId }/changeBrand`, body)
  }

  public getHubById(id: string): Observable<IBoxModel> {
    return this.http.get<IBoxModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ id }`)
  }

  public createHub(body: IBoxCreateDTO, copiedFrom?: string): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }`, {
      ...body,
      copiedFrom
    })
  }

  public deleteHub(id: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ id }`)
  }

  public resetHub(id: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.CLEAR }/${ id }`, {})
  }

  public attachBrandIntegration(boxId: string, body: Partial<IBoxBrand>): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.BRAND_ATTACHMENT }/${ boxId }`, body)
  }

  public detachBrandIntegration(boxId: string, attachmentId: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.BRAND_ATTACHMENT }/${ boxId }/${ attachmentId }`)
  }

  public clearBrandAttachmentState(boxId: string, attachmentId: string): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.BRAND_ATTACHMENT }/${ TrackerApiRoute.CLEAR }/${ boxId }/${ attachmentId }`, {})
  }

  public attachAffiliate(boxId: string, body: any): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.AFFILIATE_ATTACHMENT }/${ boxId }`, body)
  }

  public detachAffiliate(boxId: string, attachmentId: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.AFFILIATE_ATTACHMENT }/${ boxId }/${ attachmentId }`)
  }

  public findBoxAffiliateAttachments(boxId: string, attachmentId: string): Observable<IBoxAffiliate> {
    return this.http.get<IBoxAffiliate>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.AFFILIATE_ATTACHMENT }/${ boxId }/${ attachmentId }`)
  }

  public findBoxBrandAttachments(boxId: string, attachmentId: string): Observable<IBoxBrand> {
    return this.http.get<IBoxBrand>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.BRAND_ATTACHMENT }/${ boxId }/${ attachmentId }`)
  }

  public updateAffiliateAttachment(boxId: string, attachmentId: string, body: any): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.AFFILIATE_ATTACHMENT }/${ boxId }/${ attachmentId }`, body)
  }

  public updateBrandIntegrationAttachment(boxId: string, attachmentId: string, body: Partial<IBoxBrand>): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ TrackerApiRoute.BRAND_ATTACHMENT }/${ boxId }/${ attachmentId }`, body)
  }

  public updateHub(id: string, body: Partial<IBoxModel>): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BOX }/${ id }`, body)
  }

  public getVerticalById(id: string): Observable<IVerticalTemplateModel> {
    return this.http.get<IVerticalTemplateModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.VERTICAL_TEMPLATE }/${ id }`)
  }

  public createCompany(company: Partial<ICompany>): Observable<void> {
    return this.http.post<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.COMPANY }`, company)
  }

  public getCompanyById(id: string): Observable<ICompanyModel> {
    return this.http.get<ICompanyModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.COMPANY }/${ id }`)
  }

  public updateCompany(id: string, body: Partial<ICompanyModel>): Observable<ICompany> {
    return this.http.patch<ICompanyModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.COMPANY }/${ id }`, body)
  }

  public updateCompanySettings(id: string, body: Partial<ICompanySettings>): Observable<ICompanySettings> {
    return this.http.patch<ICompanySettings>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.COMPANY }/${ TrackerApiRoute.RESOURCES }/${ TrackerApiRoute.SETTINGS }/${ id }`, body)
  }

  public archiveCompany(id: string, archive: boolean): Observable<ICompany> {
    return this.http.patch<ICompany>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.COMPANY }/${ id }/${ TrackerApiRoute.ARCHIVE }`, { archive })
  }

  public updateVerticalSettings(templateId: string, id: string, body: Partial<IVerticalTemplateSettings>): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/verticalTemplate/${ templateId }/settings/${ id }`, body)
  }

  public createVerticalTemplate(data: IVerticalTemplateCreateDTO): Observable<any> {
    return this.http.post<any>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.VERTICAL_TEMPLATE }`, data)
  }

  public getTemplatePreset(verticalTemplateId: string, integrationTemplateId: string): Observable<IIntegrationTemplatePresetModel> {
    return this.http.get<IIntegrationTemplatePresetModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.INTEGRATOR }/${ TrackerApiRoute.PRESET }/${ TrackerApiRoute.LEAD_OPTIONS }/${ verticalTemplateId }/${ integrationTemplateId }`)
  }

  public getScenarioById(id: string): Observable<ISendingScenarioModel> {
    return this.http.get<ISendingScenarioModel>(`${ this.api }/${ ApiGroup.INJECTOR }/${ InjectorApiRoute.SCENARIO }/${ id }`)
  }

  public createScenario(data: Partial<ISendingScenario>): Observable<ISendingScenarioModel> {
    return this.http.post<ISendingScenarioModel>(`${ this.api }/${ ApiGroup.INJECTOR }/${ InjectorApiRoute.SCENARIO }`, data)
  }

  public updateScenario(id: string, data: Partial<ISendingScenario>): Observable<ISendingScenarioModel> {
    return this.http.patch<ISendingScenarioModel>(`${ this.api }/${ ApiGroup.INJECTOR }/${ InjectorApiRoute.SCENARIO }/${ id }`, data)
  }

  public deleteScenario(id: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.INJECTOR }/${ InjectorApiRoute.SCENARIO }/${ id }`)
  }

  public startScenario(id: string): Observable<ISendingScenarioModel> {
    return this.http.patch<ISendingScenarioModel>(`${ this.api }/${ ApiGroup.INJECTOR }/${ InjectorApiRoute.SCENARIO }/${ id }/${ InjectorApiRoute.START }`, {})
  }

  public stopScenario(id: string): Observable<ISendingScenarioModel> {
    return this.http.patch<ISendingScenarioModel>(`${ this.api }/${ ApiGroup.INJECTOR }/${ InjectorApiRoute.SCENARIO }/${ id }/${ InjectorApiRoute.STOP }`, {})
  }

  public approveConversionHold(id: string): Observable<IConversionHold> {
    return this.http.patch<IConversionHold>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.CONVERSION }/${ TrackerApiRoute.HOLD }/${ id }/${ TrackerApiRoute.APPROVE }`, {})
  }

  public declineConversionHold(id: string): Observable<IConversionHold> {
    return this.http.patch<IConversionHold>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.CONVERSION }/${ TrackerApiRoute.HOLD }/${ id }/${ TrackerApiRoute.DECLINE }`, {})
  }

  public getConversionHold(id: string): Observable<IConversionHoldModel> {
    return this.http.get<IConversionHoldModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/${ TrackerApiRoute.CONVERSION }/${ TrackerApiRoute.HOLD }/${ id }`)
  }

  public getSubscriptionById(id: string): Observable<ISubscriptionModel> {
    return this.http.get<ISubscriptionModel>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.RESOURCES }/${ TrackerApiRoute.SUBSCRIPTION }/${ id }`)
  }

  public activateBotSubscriber(id: string): Observable<IBotSubscriber> {
    return this.http.patch<IBotSubscriber>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.RESOURCES }/${ TrackerApiRoute.BOT_SUBSCRIBER }/${ id }/${ TrackerApiRoute.ACTIVATE }`, {})
  }

  public blockBotSubscriber(id: string): Observable<IBotSubscriber> {
    return this.http.patch<IBotSubscriber>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.RESOURCES }/${ TrackerApiRoute.BOT_SUBSCRIBER }/${ id }/${ TrackerApiRoute.BLOCK }`, {})
  }

  public patchBotSubscriber(id: string, body: Partial<IBotSubscriber>): Observable<IBotSubscriber> {
    return this.http.patch<IBotSubscriber>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.ACCOUNT }/${ TrackerApiRoute.RESOURCES }/${ TrackerApiRoute.BOT_SUBSCRIBER }/${ id }`, body)
  }

  public uploadLeads(params: LeadsUploadParamsModel, file: FormData): Observable<LeadsUploadResponse> {
    let url: string = `${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.LEAD }/file/upload`
    const queryParamsArray = []
    for (const key in params) {
      if (params.hasOwnProperty(key)) queryParamsArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    }
    if (queryParamsArray.length > 0) {
      url += '?' + queryParamsArray.join('&')
    }
    return this.http.post<LeadsUploadResponse>(url, file)
  }

  public updateTableConfigPortalSettings(tableKey: TableKey, columns: TableColumnsModel[]): Observable<void> {
    return this.http.patch<void>(`${ this.api }/auth/account/my/portal-settings`, {
      [`${ tableKey }TableConfig`]: columns
    })
  }

  public updateMetricsConfigPortalSettings(config: any): Observable<void> {
    return this.http.patch<void>(`${ this.api }/auth/account/my/portal-settings`, {
      'dashboardConfig': config
    })
  }

  public getPortalSettings(): Observable<PortalSettingsModel> {
    return this.http.get<PortalSettingsModel>(`${ this.api }/auth/account/my/portal-settings`)
  }

  public uploadCompanyLogo(companyId: string, file: FormData): Observable<string> {
    return this.http.post<string>(`${ this.api }/${ ApiGroup.TRACKER }/company/${ companyId }/style/logo`, file, {
      responseType: 'text' as any
    })
  }

  public deleteCompanyLogo(companyId: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/company/${ companyId }/style/logo`)
  }

  public uploadLeadAttachment(leadId: string, file: FormData): Observable<ILeadCommentAttachment> {
    return this.http.post<ILeadCommentAttachment>(`${ this.api }/${ ApiGroup.TRACKER }/lead/comment/${ leadId }/attachment/upload`, file)
  }

  public createLeadComment(leadId: string, message: string, attachments: ILeadCommentAttachment[]): Observable<ILeadCommentModel> {
    return this.http.post<ILeadCommentModel>(`${ this.api }/${ ApiGroup.TRACKER }/lead/comment/${ leadId }`, {
      message,
      attachments
    })
  }

  public deleteLeadComment(leadId: string, commentId: string): Observable<void> {
    return this.http.delete<void>(`${ this.api }/${ ApiGroup.TRACKER }/lead/comment/${ leadId }/${ commentId }`)
  }

  public searchFraudProfiles = (params: Partial<OffsetSearchRequestParams>): Observable<ISearchResponseDTO<any>> => {
    return this.getOffsetSearchRequest({ url: `${ this.api }/tracker/company/resources/fraudProfile`, ...params }).pipe(
      map(({ rows, count }) => ({
        count,
        rows: rows.map((row: any) => ({
          ...row,
          name: row.isEnabled ? row.name : `${ row.name } [OFF]`
        }))
      }))
    )
  }

  public brandFraudCheck(id: string, body: any): Observable<void> {
    return this.http.patch<void>(`${ this.api }/${ ApiGroup.TRACKER }/${ TrackerApiRoute.BRAND }/${ id }/settings/fraudBlock`, body)
  }

  public leadBulkChangeFunnel(body: { funnel: string, ids: string[] }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/bulk/changeFunnel`, body)
  }

  public leadBulkChangeGeo(body: { geo: string, ids: string[] }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/bulk/changeGeo`, body)
  }

  public leadBulkChangeLang(body: {
    lang: string | null,
    landingLang: string | null,
    ids: string[]
  }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/bulk/changeLang`, body)
  }

  public leadBulkChangeSub(body: {
    subId_a: string | null,
    subId_b: string | null,
    subId_c: string | null,
    subId_d: string | null,
    subId_e: string | null,
    subId_f: string | null,
    ids: string[]
  }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/bulk/changeSub`, body)
  }

  public leadBulkChangeUtm(body: {
    utmId: string | null,
    utmSource: string | null,
    utmMedium: string | null,
    utmCampaign: string | null,
    ids: string[]
  }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/bulk/changeUtm`, body)
  }

  public leadBulkChangeBrandStatus(body: { rawStatus: string, ids: string[] }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/bulk/changeBrandStatus`, body)
  }

  public leadBulkChangeFrozenBrandStatus(body: { frozenBrandStatus: string, ids: string[] }): Observable<void> {
    return this.http.patch<void>(`${ this.api }/tracker/lead/bulk/changeHoldBrandStatus`, body)
  }
}
